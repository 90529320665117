exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cleophas-js": () => import("./../../../src/pages/cleophas.js" /* webpackChunkName: "component---src-pages-cleophas-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-overview-js": () => import("./../../../src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-room-detail-js": () => import("./../../../src/templates/roomDetail.js" /* webpackChunkName: "component---src-templates-room-detail-js" */)
}

